<template>
  <div id="m-label">
    <span
      class="label font-weight-bold label-lg"
      :class="`label-${cpLabel.variant} label-inline`"
    >
      <slot>
        {{ text }}
      </slot>
    </span>
  </div>
</template>
  
<script>
export default {
  props: {
    value: {
      value: '',
      required: true,
    },
    text: {
      value: null,
      default: 'Chưa gán',
    },
  },
  data() {
    return {
      defaultLabel: {
        1: {
          variant: 'light-success',
        },
        2: {
          variant: 'light-danger',
        },
      },
      label: null,
    };
  },
  computed: {
    cpLabel() {
      return (
        this.defaultLabel[this.value] || {
          variant: 'light-secondary',
        }
      );
    },
  },
};
</script>
  
<style lang="scss">
#m-label {
  width: max-content;
}
</style>
  