<template>
  <div>
    <b-tabs pills card vertical>
      <b-tab
        title="Giới hạn đăng nhập theo IP"
        @click="onChangeTypeTab('ip')"
        active
        ><b-card-text>
          <b-row class="mb-5">
          <b-col class="pl-0">
              <b-input
                size="sm"
                placeholder="Nhập địa chỉ ip"
                v-model="paramsFilter.ip"
                @keyup="debounceFetchData()"
              ></b-input>
            </b-col>
            <b-col>
                <b-form-select
                  class="select-style"
                  size="sm"
                  v-model="paramsFilter.status"
                  :options="options"
                    value-field="id"
                    text-field="name"
                  @change="debounceFetchData()"
                >
                  <template #first>
                    <b-form-select-option :value="null">--Trạng thái--</b-form-select-option>
                  </template>
                </b-form-select>
            </b-col>
            <b-col>
              <b-button
                style="width: 70px"
                variant="primary"
                size="sm"
                class="font-weight-bolder"
                @click="fetchData()"
                >Lọc
              </b-button>
            </b-col>
            <b-col class="text-right">
              <router-link
                :to="{
                  name: 'create-user-access',
                  params: {
                    type: 'ip',
                  },
                }"
              >
                <b-button
                  variant="primary"
                  size="sm"
                  class="font-weight-bolder"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  Tạo mới
                </b-button>
              </router-link>
            </b-col>
          </b-row>
          <b-table
            :items="whiteListIpDataTables"
            :fields="whiteListIpFieldTables"
            :busy="onLoading"
            bordered
            hover
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
            <template v-slot:cell(index)="row">
              <span class="justify-content-center"> {{ (row.index + 1) + ((currentPage - 1) * 10) }}</span>
            </template>
            <template v-slot:cell(status)="row">
              <MLabel v-model="row.item.status"
                >{{ row.item.statusName }}
              </MLabel>
            </template>
            <template v-slot:cell(actions)="row" v-if="checkPermissions(['USER_ACCESS_DELETE','USER_ACCESS_UPDATE'])">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editData(row.item)" v-if="checkPermission('USER_ACCESS_UPDATE')">
                    <span style="color: #3f4254">
                      <i style="font-size: 1rem" class="flaticon2-pen mr-1"></i>
                      Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_UPDATE')"
                    @click="
                      disableData(
                        row.item,
                        row.item.status === 1 ? 'lock' : 'unlock',
                      )
                    "
                  >
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 1"
                      class="text-danger ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock text-danger mr-1"
                      ></i>
                      Thu hồi
                    </span>
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 2"
                      class="text-success ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock-open text-success mr-1"
                      ></i>
                      Hoạt động
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_DELETE')"
                    @click="disableData(row.item, 'soft-delete')"
                  >
                    <span style="font-size: 12px" class="text-danger">
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-trash text-danger mr-1"
                      ></i>
                      Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số:
                {{ totalItems }}
              </p>
            </b-col>
            <b-col>
              <b-col cols="12">
                <b-pagination-nav
                  v-show="totalItems > 10"
                  pills
                  :number-of-pages="numberOfPage"
                  :link-gen="linkGen"
                  v-model="currentPage"
                  align="right"
                  use-router
                  @change="fetchData()"
                ></b-pagination-nav>
              </b-col>
            </b-col>
          </b-row> </b-card-text
      ></b-tab>
    </b-tabs>
  </div>
</template>


<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice, makeToastSuccess, makeToastFaile } from '@/utils/common';
import { showConfirmDialog } from '@/utils/sweet-alert2'
import MLabel from '@/view/base/metronic/MLabel.vue';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { USER_ACCESS_STATUS } from '@/utils/enum';
import VclTable from 'vue-content-loading';
import { checkPermission, checkPermissions } from '@/utils/saveDataToLocal';
export default {
  data() {
    return {
      pageTitle: '',
      totalItems: 0,
      numberOfPage: 1,
      currentPage: 1,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoading: false,
      whiteListIpFieldTables: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'font-weight-bolder text-center',
        },
        {
          key: 'ip',
          label: 'Địa chỉ ip',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        },
        // {
        //   key: 'accessAllows',
        //   label: 'Giới hạn theo',
        //   sortable: false,
        //   thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        // },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        // {
        //   key: 'expireOn',
        //   label: 'Thời hạn',
        //   sortable: false,
        //   thStyle: {
        //     fontWeight: 600,
        //     color: '#181c32',
        //     width: '10%',
        //     textAlign: 'center',
        //   },
        //   tdClass: 'text-center',
        // },
        {
          key: 'actions',
          label: '',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
      ],
      paramsFilter: {
        ip: null,
        status: null,
      },
      type: 'ip',
      whiteListIpDataTables: [],
      options:[
        {id: USER_ACCESS_STATUS.ACTIVE, name: 'Kích hoạt'},
        {id: USER_ACCESS_STATUS.DEACTIVE, name: 'Đã khoá'}
      ],
    };
  },
  components: {
    MLabel,
    VclTable,
  },
  computed: {},
  mounted() {
    this.pageTitle = 'Danh sách cấu hình truy cập';
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  created() {
    this.fetchData();
  },
  methods: {
    checkPermission,
    checkPermissions,
    debounce,
    showConfirmDialog,
    convertPrice,
    fetchData() {
      this.onLoading = true;
      const PAGE_SIZE = 10;
      this.currentPage = this.$route.query.page || 1;
      ApiService.query('/ip-configurations', {
        params: {
          size: PAGE_SIZE,
          page: this.paramsFilter.ip ? this.currentPage = 1 : this.currentPage,
          ...this.paramsFilter,
          type: this.type,
        },
      }).then((response) => {
        const { data, total, total_page } = response.data.data;
        this.totalItems = total;
        this.numberOfPage = total_page || 1;
        this.whiteListIpDataTables = data;
      }).catch(err=>{
        const { message } = err.response?.data || 'Lỗi';
        makeToastFaile( message );
      }).finally(() => {
        this.onLoading = false
      });
    },
    editData(item) {
      this.$router.push({
        name: 'update-user-access',
        params: { type: this.type, id: item.ip },
      });
    },
    disableData(item, type) {
      if (this.onLoading) {
        return;
      }
      const disableText = {
        lock: {
          name: 'Khoá',
        },
        unlock: {
          name: 'Mở khoá',
        },
        'soft-delete': {
          name: 'Xoá',
        },
      };
      this.showConfirmDialog(`<h1 class='text-danger'>Bạn có chắc muốn ${disableText[type].name}</h1>`,
        `${disableText[type].name} ứng dụng khônng thể hoàn tác!`)
        .then(async () => {
          try {
            this.onLoading = true;
            const result = await ApiService.patch(`ip-configurations/${item.id}/disable/`, {
              type,
            })
            makeToastSuccess(result.data.message);
            this.fetchData();
            this.onLoading = false;
          } catch (error) {
            this.onLoading = false;
            if (error.response) {
              makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi',
              );
            } else {
              makeToastFaile(error.message || 'Lỗi');
            }
          };
        }).catch(err => {
          this.onLoading = false;
          console.log(err);
        });
    },
    onChangeTypeTab(type) {
      this.type = type;
      this.fetchData();
      this.onInitData();
    },
    debounceFetchData: debounce(function(){
      this.fetchData();
    },TIME_TRIGGER)
  },
};
</script>
<style lang="scss" scoped>
.white-list-ip ::v-deep {
  #card-middle-content {
    .table th,
    .table td {
      padding: 0.75rem;
      vertical-align: middle;
      border-top: 1px solid #ecf0f3;
    }

    .table thead tr > th {
      text-align: center;
    }

    padding: 10px;
    .card-header {
      padding: 1rem 1rem;
    }
    .card-body {
      padding: 1rem 1.25rem;
    }
    .col-auto {
      width: 30%;
      ul > li {
        border: 0.5px solid #ebedf0;
      }
    }
    .nav-pills .nav-link {
      border-radius: 0rem;
    }
    .nav.nav-pills .nav-link {
      color: #000000;
    }
    .nav.nav-pills .nav-link:hover {
      color: #3699ff;
    }
    .nav.nav-pills .show > .nav-link,
    .nav.nav-pills .nav-link.active {
      background-color: #f3f3f7 !important;
      color: #3699ff;
    }
  }
}
</style>


